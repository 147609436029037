<template>
  <div class="body_section">
    <aside class="steps_menu" style="display: none">
      <div class="logo">
        <router-link to="/dashboard">
          <img src="@/assets/images/logo.svg" />
          <img class="logo_text" src="@/assets/images/logo_text.svg" />
        </router-link>
      </div>
      <!--
            blocks:{"form1":{"active":true ,
            "validator":"legal_informationForm" ,
            "label":'General Information' ,
             "formIsValied":false} ,-->
      <div class="registration_menu">
        <ul id="progressbar">
          <li
            @click="
              show_block = 1;
              togleBlock();
            "
            :class="{
              active: show_block >= 1,
              completed: blocks.form1.formIsValied,
            }"
          >
            <p><span class="legal"></span>General Information</p>
          </li>
          <li
            @click="
              show_block = 2;
              togleBlock();
            "
            :class="{
              active: show_block >= 2,
              completed: blocks.form1.formIsValied && blocks.form2.formIsValied,
            }"
          >
            <p><span class="business"></span>Business Information</p>
          </li>
          <li
            @click="
              show_block = 3;
              togleBlock();
            "
            :class="{
              active: show_block >= 3,
              completed:
                blocks.form1.formIsValied &&
                blocks.form2.formIsValied &&
                blocks.form3.formIsValied,
            }"
          >
            <p><span class="owner"></span>Owner Details</p>
          </li>
          <li
            @click="
              show_block = 4;
              togleBlock();
            "
            :class="{
              active: show_block >= 4,
              completed:
                blocks.form1.formIsValied &&
                blocks.form2.formIsValied &&
                blocks.form3.formIsValied &&
                blocks.form4.formIsValied,
            }"
          >
            <p><span class="states"></span>States Registered</p>
          </li>
        </ul>
        <figure>
          <img src="@/assets/images/registration_img.svg" alt="registration" />
        </figure>
      </div>
    </aside>
    <profileHeader
      :showFilter="true"
      @changedGlobalclient="changedGlobalclient"
    />
    <div class="body_content" v-if="ClientDataloaded">
      <div class="client_details_head_wrap">
        <div class="client_details_head">
          <ul>
            <li
              @click="
                show_block = 1;
                togleBlock();
              "
              :class="{ active: show_block == 1 }"
            >
              <p>General Information</p>
            </li>
            <li
              @click="
                show_block = 2;
                togleBlock();
              "
              :class="{ active: show_block == 2 }"
            >
              <p>Business Information</p>
            </li>
            <li
              @click="
                show_block = 3;
                togleBlock();
              "
              :class="{ active: show_block == 3 }"
            >
              <p>Owner Details</p>
            </li>
            <li
              @click="
                show_block = 4;
                togleBlock();
              "
              :class="{ active: show_block == 4 }"
            >
              <p>States Registered</p>
            </li>
          </ul>
          <div>
            <span class="questionnaire_edit">
              <router-link :to="'/questionnaire/' + this.Payload.clientId">
                <b-icon icon="pencil-square" aria-hidden="true"></b-icon>Edit
              </router-link>
            </span>
            <!--<span class="questionnaire_edit" @click="generateData()" style="cursor:pointer">Shopify</span>-->
          </div>
        </div>
      </div>
      <div class="main_content">
        <div class="questionnaire_details_wrap">
          <!-- <span class="questionnaire_edit">
                        <router-link :to="'/questionnaire/'+this.Payload.clientId">
                         <b-icon icon="pencil-square" aria-hidden="true"></b-icon>Edit
                         </router-link>
                         </span>
                        <span class="questionnaire_edit" @click="generateData()" style="cursor:pointer">Shopify</span> -->

          <div
            class="questionnaire_details_list"
            id="legalinformation"
            v-if="blocks.form1.active"
          >
            <!-- <div class="questionnaire_details_head">
                                <h3>General Information</h3>
                                <p>*Please ensure the information on this form is correct, including spelling, this is the exact information we give to the states</p>
                            </div> -->
            <div>
              <div class="questionnaire_details_cnt">
                <ul>
                  <li>
                    <label>Legal Name Of Business</label>

                    <p>{{ checkProperty(clientData, 'busName') }}</p>
                  </li>
                  <li>
                    <label>Trade Name (DBA)</label>
                    <p>{{ checkProperty(clientData, 'tradeName') }}</p>
                  </li>
                </ul>
              </div>
              <div class="questionnaire_details_cnt">
                <h4 class="title">BUSINESS ADDRESS</h4>
                <div>
                  <viewAddressComponent
                    v-bind:address="checkProperty(clientData, 'busAddress')"
                  />
                </div>
              </div>
              <div class="questionnaire_details_cnt">
                <h4 class="title">MAILING ADDRESS</h4>
                <viewAddressComponent
                  v-bind:address="checkProperty(clientData, 'mailingAddress')"
                />
              </div>
              <div class="questionnaire_details_cnt">
                <h4 class="title">LOCATION OF TAX RECORDS</h4>
                <viewAddressComponent
                  v-bind:address="
                    checkProperty(clientData, 'taxRecordLocation')
                  "
                />
              </div>
            </div>
          </div>
          <div
            class="questionnaire_details_list"
            id="businessinformation"
            v-if="blocks.form2.active"
          >
            <!-- <div class="questionnaire_details_head">
                                <h3>Business Information</h3>
                                <p>*Please ensure the information on this form is correct, including spelling, this is the exact information we give to the states</p>
                            </div> -->
            <div>
              <div class="questionnaire_details_cnt">
                <ul>
                  <li>
                    <label>Business Email</label>
                    <p>
                      {{ checkProperty(clientData, 'busEmail') }}
                    </p>
                  </li>
                  <li>
                    <label>Business Phone Number</label>
                    <p>
                      <template
                        v-if="
                          checkProperty(
                            clientData,
                            'busContact',
                            'phoneCode',
                          ) != '--' &&
                          checkProperty(
                            clientData,
                            'busContact',
                            'phoneCode',
                          ) != ''
                        "
                      ></template>
                      {{ checkProperty(clientData, 'busContact', 'phone') }}
                    </p>
                  </li>
                  <li>
                    <label>Business Representative Name</label>
                    <p>
                      {{ checkProperty(clientData, 'busContact', 'name') }}
                    </p>
                  </li>
                  <li>
                    <label>Business Representative Phone</label>
                    <p>
                      {{ checkProperty(clientData, 'busContact', 'phone') }}
                    </p>
                  </li>
                  <li>
                    <label>Business Representative Email</label>
                    <p>
                      {{ checkProperty(clientData, 'busContact', 'email') }}
                    </p>
                  </li>
                  <li>
                    <label>Business Website</label>
                    <p>
                      {{ checkProperty(clientData, 'busContact', 'website') }}
                    </p>
                  </li>
                  <!--
                                        <li>
                                            <label>Federal Employer ID</label>
                                            <p>
                                             {{checkProperty(clientData ,'fedaralEmpId' )}}
                                            </p>
                                        </li>
                                        -->
                  <li>
                    <label>Date Of Incorporation/Formation</label>
                    <p>
                      {{
                        checkProperty(clientData, 'busFormatedOn') | formatDate
                      }}
                    </p>
                  </li>
                  <li>
                    <label>Entity Type</label>
                    <p>
                      {{
                        checkProperty(clientData, 'busStructureDetails', 'name')
                      }}
                    </p>
                  </li>

                  <li>
                    <label>EIN</label>
                    <p>
                      {{ checkProperty(clientData, 'EIN') }}
                    </p>
                  </li>
                  <li>
                    <label>Incorporation State</label>
                    <p>
                      {{
                        checkProperty(
                          clientData,
                          'incorporatedStateDetails',
                          'name',
                        )
                      }}
                    </p>
                  </li>
                  <!-- <li  class="uploaded-list">
                                            <label>Uploaded Documents</label>
                                                    <template v-for="(other, indx) in Payload.articlesOfOrgAndDriverLiecenses" >
                                                    <li :key="indx" > {{ other.name }}  <span @click="download_file(other)" >download</span></li>
                                                    </template>
                                                </li> -->
                </ul>

                <ul class="column-one">
                  <li>
                    <label>Secretary of State Numbers</label>
                    <template
                      v-if="
                        checkProperty(clientData, 'secretaryStateNumbers') !=
                        '--'
                      "
                    >
                      <template
                        v-for="(
                          iden, index
                        ) in clientData.secretaryStateNumbers"
                      >
                        <ul class="documents_list" :key="index">
                          <li>
                            <label>Number</label>
                            <p>{{ checkProperty(iden, 'number') }}</p>
                          </li>
                        </ul>
                      </template>
                    </template>
                  </li>
                </ul>

                <ul class="column-one">
                  <li>
                    <label>Incorporation State Documents</label>
                    <ul class="documents_list">
                      <li
                        v-for="(doc, docIndex) in clientData[
                          'articlesOfOrgAndDriverLiecenses'
                        ]"
                        :key="docIndex"
                      >
                        <p>{{ checkProperty(doc, 'name') }}</p>
                        <!-- <a @click="download_file(doc)"
                          ><b-icon icon="download" aria-hidden="true"></b-icon
                        ></a> -->
                        <a :href="doc.url" target="_blank" download>
                          <b-icon icon="download" aria-hidden="true"></b-icon>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div class="questionnaire_details_cnt">
                <h6>
                  If LLC, what have you elected to be treated as for Federal
                  Income Tax Purposes?
                </h6>
                <!-- {{clientData.llcTypeDetails}} -->
                <ul>
                  <template
                    v-if="checkProperty(clientData, 'llcTypeDetails') != '--'"
                  >
                    <li>
                      <label>Entity Type</label>
                      <p>
                        {{
                          checkProperty(
                            clientData,
                            'busStructureDetails',
                            'name',
                          )
                        }}
                      </p>
                    </li>
                  </template>
                </ul>
              </div>
              <div class="questionnaire_details_cnt">
                <h4 class="title">ACCOUNTING METHOD</h4>
                <ul class="column-two">
                  <li>
                    <label>Accounting Method</label>
                    <p>
                      {{
                        getNameFromObject({
                          object: clientData['accMethodDetails'],
                          namekey: 'name',
                          idkey: '_id',
                          idValue: checkProperty(clientData, 'accMethodId'),
                        })
                      }}
                    </p>
                  </li>

                  <!-- <li v-if="checkProperty(clientData, 'previousFilingEndDate')">
                    <label>Previous Filing End Date</label>
                    <p>
                      {{ clientData["previousFilingEndDate"] | formatDate }}
                    </p>
                  </li> -->
                  <li>
                    <label>Tax Beginning Period</label>
                    <p>
                      {{ getMonthName() }}

                      <!-- {{getProperty( iden._id , checkProperty( clientData ,'taxBegginingPeriodDetails') )}} -->
                      <!-- {{getNameFromObject({object:clientData['taxBegginingPeriodDetails'] ,namekey:"name" , idkey:id, idValue:checkProperty(clientData ,'taxBegginingPeriod')})}} -->
                    </p>
                  </li>
                  <!-- <li>
                                            <label>Accounting Closes On</label>
                                            <p>{{checkProperty(clientData ,'accClosedOn') | formatDateDM}}</p>
                                        </li> -->
                  <!--<li>
                    <label>Nexus (Physical/Economical/Both)</label>
                    <p>
                      {{
                        checkProperty(clientData, "nexusTypeDetails", "name")
                      }}
                    </p>
                  </li>-->
                  <li>
                    <label>Description of Items Sold</label>
                    <p>{{ checkProperty(clientData, 'itemDescription') }}</p>
                  </li>
                  <li>
                    <label>NAICS Code</label>
                    <p>{{ checkProperty(clientData, 'NAICSCode') }}</p>
                  </li>
                  <li>
                    <label>Industry</label>

                    <template
                      v-for="(industry, iindx) in checkProperty(
                        clientData,
                        'industryIds',
                      )"
                    >
                      <p :key="iindx">
                        {{ industry }}
                      </p>
                    </template>
                  </li>
                </ul>
              </div>
              <div class="questionnaire_details_cnt">
                <h4 class="title">BUSINESS TYPE</h4>

                <ul>
                  <li>
                    <label>Type Of Business</label>
                    <p>
                      <!-- {{getProperty( iden._id , checkProperty( clientData ,'businessTypeDetails') )}}  -->
                      <template>
                        <p
                          v-for="(type, ind) in clientData[
                            'businessTypeDetails'
                          ]"
                          :key="ind"
                        >
                          {{ checkProperty(type, 'name') }}
                        </p>
                      </template>
                      <!-- {{getProperty({object:clientData['businessTypeDetails'] ,namekey:"name" , idkey:"_id", idValue:checkProperty(clientData ,'busTypeIds')})}} -->
                    </p>
                  </li>
                  <!-- <li>
                                            <label>Do you use taxjar, API or any website for calculating sales tax that should be collected by the client?</label>
                                            <p v-if="checkProperty(clientData,'havePhysicalStore') ==true" >Yes</p>
                                            <p v-else >No</p>
                                        </li> -->
                  <li>
                    <label>Physical Store</label>
                    <p
                      v-if="
                        checkProperty(clientData, 'havePhysicalStore') == true
                      "
                    >
                      Yes
                    </p>
                    <p v-else>No</p>
                  </li>
                  <template
                    v-if="
                      checkProperty(clientData, 'havePhysicalStore') == true &&
                      checkProperty(clientData, 'physicalStoreAddress')
                    "
                  >
                    <li>
                      <label>Address</label>
                      <p>
                        {{
                          checkProperty(
                            clientData,
                            'physicalStoreAddress',
                            'addr2',
                          )
                        }}
                      </p>
                    </li>
                    <li>
                      <label>Country </label>
                      <p>
                        {{
                          checkProperty(
                            clientData.physicalStoreAddress,
                            'countryDetails',
                            'name',
                          )
                        }}
                      </p>
                    </li>
                    <li>
                      <label>State</label>
                      <p>
                        {{
                          checkProperty(
                            clientData.physicalStoreAddress,
                            'stateDetails',
                            'name',
                          )
                        }}
                      </p>
                    </li>
                    <li>
                      <label>City</label>
                      <p>
                        {{
                          checkProperty(
                            clientData.physicalStoreAddress,
                            'locationDetails',
                            'name',
                          )
                        }}
                      </p>
                    </li>
                    <li>
                      <label>Zip</label>
                      <p>
                        {{
                          checkProperty(clientData.physicalStoreAddress, 'zip')
                        }}
                      </p>
                    </li>
                  </template>
                  <!--<li>
                    <label>Selling Platforms</label>
                    <template
                      v-if="
                        checkProperty(clientData, 'sellingPlatformDetails') !=
                          '--'
                      "
                    >
                      <p
                        v-for="(sp, index) in clientData.sellingPlatformDetails"
                        :key="index"
                      >
                        {{ checkProperty(sp, "name") }}
                      </p>
                    </template>
                  </li>-->
                  <li>
                    <label>TaxJar, Avalara, Shopify or Custom API</label>
                    <p>
                      {{ checkProperty(clientData, 'taxJarDescription') }}
                    </p>
                  </li>
                  <li>
                    <label>Store Address/Url</label>
                    <p>
                      {{ checkProperty(clientData, 'shopifyUrlOrAddress') }}
                    </p>
                  </li>
                  <li v-if="checkProperty(clientData, 'hostDetails', 'name')">
                    <label>What platform is this email hosted on?</label>
                    <p>
                      {{ checkProperty(clientData, 'hostDetails', 'name') }}
                    </p>
                    <p>
                      {{ checkProperty(clientData, 'customPlatformHosted') }}
                    </p>
                  </li>
                  <li v-if="checkProperty(clientData, 'hostDetails', 'name')">
                    <label>What is the email</label>
                    <p>
                      {{ checkProperty(clientData, 'emailPlatformHosted') }}
                    </p>
                  </li>
                  <template>
                    <li v-if="checkProperty(clientData, 'hostDetails', 'name')">
                      <label>What is the password</label>
                      <p>
                        {{
                          isPasswordVisible
                            ? checkProperty(
                                clientData,
                                'passwordPlatformHosted',
                              )
                            : '••••••'
                        }}

                        <!-- Eye Icon to toggle visibility -->
                        <span @click="togglePasswordVisibility">
                          <b-icon
                            v-if="isPasswordVisible"
                            icon="eye-fill"
                            aria-hidden="true"
                          ></b-icon>

                          <b-icon
                            v-else
                            icon="eye-slash-fill"
                            aria-hidden="true"
                          ></b-icon>
                        </span>
                      </p>
                    </li>
                  </template>
                  <li>
                    <label>Marketplace Facilitator?</label>

                    <p
                      v-if="
                        checkProperty(clientData, 'marketPlaceFecilitator') ==
                        true
                      "
                    >
                      Yes
                    </p>
                    <p v-else>No</p>
                  </li>

                  <li>
                    <label
                      >Business involved in a merger within the last seven
                      years</label
                    >
                    <p
                      v-if="
                        checkProperty(clientData, 'involvedInMerge') == true
                      "
                    >
                      Yes
                    </p>
                    <p v-else>No</p>
                  </li>
                  <li>
                    <label>Seller On Marketplace</label>
                    <p
                      v-if="
                        checkProperty(clientData, 'sellOnMarketPlace') == true
                      "
                    >
                      Yes
                    </p>
                    <p v-else>No</p>
                  </li>

                  <li>
                    <label>Marketplace</label>
                    <p v-for="id in clientData['marketPlaceSellId']" :key="id">
                      {{ getMarketplaceById(id) }}
                    </p>
                    <p>{{ checkProperty(clientData, 'otherMarketPlace') }}</p>
                  </li>

                  <li>
                    <label
                      >Has the business been notified of any changes to previous
                      returns by any taxing authority? If yes, please indicate
                      which state and reason.</label
                    >
                    <p
                      v-if="
                        checkProperty(
                          clientData,
                          'previousReturnNotifyChanges',
                        ) == true
                      "
                    >
                      Yes
                    </p>
                    <p v-else>No</p>
                  </li>

                  <li
                    v-if="
                      checkProperty(
                        clientData,
                        'previousReturnNotifyChanges',
                      ) == true
                    "
                  >
                    <label>States</label>
                    <template
                      v-if="
                        checkProperty(
                          clientData,
                          'previousReturnNotifyStates',
                        ) != '--'
                      "
                    >
                      <p
                        v-for="(state, index) in clientData[
                          'previousReturnNotifyStates'
                        ]"
                        :key="index"
                      >
                        {{
                          getProperty(
                            state['stateId'],
                            clientData['previousReturnNotifyStateDetails'],
                          )
                        }}
                        <br />

                        Reason: {{ checkProperty(state, 'reason') }}
                      </p>
                    </template>
                  </li>

                  <li>
                    <label>Purchased Existing Business</label>
                    <p
                      v-if="
                        checkProperty(clientData, 'purchaseExistingBusiness') ==
                        true
                      "
                    >
                      Yes
                    </p>
                    <p v-else>No</p>
                  </li>
                  <!--<li>
                    <label>Owners more than 20%</label>
                    <p>
                      {{ checkProperty(clientData, "ownersMorethan20Percent") }}
                    </p>
                  </li>-->
                  <li>
                    <label
                      >Is your company a subsidary of a parent company?</label
                    >
                    <p
                      v-if="
                        checkProperty(
                          clientData,
                          'isSubsidaryOfParentCompany',
                        ) == true
                      "
                    >
                      Yes
                    </p>
                    <p v-else>No</p>
                  </li>
                  <li>
                    <template
                      v-if="
                        checkProperty(
                          clientData,
                          'isSubsidaryOfParentCompany',
                        ) == true
                      "
                    >
                      <label>Company Name</label>
                      <p>
                        {{ checkProperty(clientData, 'parentCompanyName') }}
                      </p>
                    </template>
                  </li>
                  <li>
                    <template
                      v-if="
                        checkProperty(
                          clientData,
                          'isSubsidaryOfParentCompany',
                        ) == true
                      "
                    >
                      <label> Cap Table documents</label>
                      <ul class="documents_list">
                        <li
                          v-for="(other, indx) in clientData.capTableDocs"
                          :key="indx"
                        >
                          <p>{{ checkProperty(other, 'name') }}</p>
                          <a :href="other.url" target="_blank" download>
                            <b-icon icon="download" aria-hidden="true"></b-icon>
                          </a>
                          <!-- <a @click="download_file(other)"
                            ><b-icon icon="download" aria-hidden="true"></b-icon
                          ></a> -->
                        </li>
                      </ul>
                    </template>
                  </li>
                </ul>
              </div>
              <div class="questionnaire_details_cnt">
                <h4 class="title">Employees</h4>

                <ul>
                  <li>
                    <label
                      >Staff/Agents (1099) presence In Multiple States</label
                    >
                    <p
                      v-if="
                        checkProperty(clientData, 'haveEmployeesOrAgents') ==
                        true
                      "
                    >
                      Yes
                    </p>
                    <p v-else>No</p>
                  </li>
                  <li
                    v-if="
                      checkProperty(clientData, 'haveEmployeesOrAgents') == true
                    "
                  >
                    <label>States</label>
                    <template
                      v-if="checkProperty(clientData, 'otherBusStates') != '--'"
                    >
                      <p
                        v-for="(state, index) in clientData['otherBusStates']"
                        :key="index"
                      >
                        {{ checkProperty(state, 'name') }}
                      </p>
                    </template>
                  </li>

                  <li>
                    <label
                      >States where have employees and run payroll for
                      (W-2).</label
                    >
                    <template
                      v-if="
                        checkProperty(clientData, 'empPayrollStateDetails') !=
                        '--'
                      "
                    >
                      <p
                        v-for="(state, index) in clientData[
                          'empPayrollStateDetails'
                        ]"
                        :key="index"
                      >
                        {{ checkProperty(state, 'name') }}
                      </p>
                    </template>
                  </li>

                  <li
                    v-if="
                      checkProperty(clientData, 'haveEmployeesOrAgents') == true
                    "
                  >
                    <label>States</label>
                    <template
                      v-if="checkProperty(clientData, 'otherBusStates') != '--'"
                    >
                      <p
                        v-for="(state, index) in clientData['otherBusStates']"
                        :key="index"
                      >
                        {{ checkProperty(state, 'name') }}
                      </p>
                    </template>
                  </li>

                  <!--<li>
                    <label>Corporate Website</label>
                    <p>{{ checkProperty(clientData, "website") }}</p>
                  </li>-->
                  <li>
                    <label
                      >Payroll Tax ID Number/Unemployment Insurance (UI) Tax
                      Number (Ex. CA – 123456, TX - 987654</label
                    >
                    <p>{{ checkProperty(clientData, 'taxNumber') }}</p>
                  </li>
                </ul>
              </div>
              <div class="questionnaire_details_cnt">
                <!-- {{clientData.identifiers}} -->
                <h4 class="title">Store Access</h4>
                <template>
                  <ul class="line">
                    <li>
                      <label>Shopify Link</label>
                      <p>
                        {{
                          checkProperty(
                            clientData.identifiers[0],
                            'shopifyLink',
                          )
                        }}
                      </p>
                    </li>
                    <li>
                      <label>Collaborator Code</label>
                      <p>
                        {{
                          checkProperty(
                            clientData.identifiers[0],
                            'collaboratorCode',
                          )
                        }}
                      </p>
                    </li>
                    <!-- Additional items can be added here, directly referencing clientData.identifiers[0] as needed -->
                  </ul>
                </template>
                <!--<template
                  v-if="checkProperty(clientData, 'identifiers') != '--'"
                >
                  <template v-for="(iden, index) in clientData.identifiers">
                    <ul class="line" :key="index">
                      <li>
                        <label>shopifyLink</label>
                        <p>{{ checkProperty(clientData.identifiers[0], 'shopifyLink') }}</p>
                      </li>
                      
                      <li>
                        <label>Collaborator Code</label>
                        <p>{{ checkProperty(clientData.identifiers[0], 'collaboratorCode') }}</p>
                      </li>
                      <li :key="index">
                        <label>Selling Platform</label>
                        <p v-if="checkProperty(iden, 'platform') == true">
                          {{ checkProperty(iden, "platform") }}
                        </p>
                        <p v-else>Shopify</p>
                      </li>
                      <li>
                        <label>Unique ID</label>
                        <p>{{ checkProperty(iden, "identifier") }}</p>
                      </li>
                      <li v-if="iden.clientKey">
                        <label>Client Key</label>
                        <div class="d-flex align-items-center">
                          <p
                            v-if="iden.clientKey && clientTypeId == 'password'"
                            class="marr30"
                          >
                            *****
                          </p>
                          <p
                            v-if="
                              checkProperty(iden, 'clientKey') &&
                                clientTypeId == 'text'
                            "
                            class="marr30"
                          >
                            {{ checkProperty(iden, "clientKey") }}
                          </p>
                          <template v-if="checkProperty(iden, 'clientKey')">
                            <template v-if="clientTypeId == 'password'">
                              <div
                                class="eye_icon"
                                title="show"
                                @click="clientTypeId = 'text'"
                              >
                                <b-icon
                                  icon=" eye-fill"
                                  aria-hidden="true"
                                ></b-icon>
                              </div>
                            </template>
                            <template v-else>
                              <div
                                class="eye_icon"
                                title="hide"
                                @click="clientTypeId = 'password'"
                              >
                                <b-icon
                                  icon="eye-slash-fill"
                                  aria-hidden="true"
                                ></b-icon>
                              </div>
                            </template>
                          </template>
                        </div>
                      </li>
                      <li v-if="iden && iden.clientSecret">
                        <label>Client Secret</label>
                        <div class="d-flex align-items-center">
                          <p
                            v-if="
                              iden &&
                                iden.clientSecret &&
                                clientTypeSecret == 'password'
                            "
                            class="marr30"
                          >
                            *****
                          </p>
                          <p
                            v-if="
                              checkProperty(iden, 'clientSecret') &&
                                clientTypeSecret == 'text'
                            "
                            class="marr30"
                          >
                            {{ checkProperty(iden, "clientSecret") }}
                          </p>
                          <template v-if="checkProperty(iden, 'clientSecret')">
                            <template v-if="clientTypeSecret == 'password'">
                              <div
                                class="eye_icon"
                                title="show"
                                @click="clientTypeSecret = 'text'"
                              >
                                <b-icon
                                  icon=" eye-fill"
                                  aria-hidden="true"
                                ></b-icon>
                              </div>
                            </template>
                            <template v-else>
                              <div
                                class="eye_icon"
                                title="hide"
                                @click="clientTypeSecret = 'password'"
                              >
                                <b-icon
                                  icon="eye-slash-fill"
                                  aria-hidden="true"
                                ></b-icon>
                              </div>
                            </template>
                          </template>
                        </div>
                      </li>
                    </ul>
                  </template>
                </template>-->
              </div>
              <div class="questionnaire_details_cnt">
                <h4 class="title">BANK DETAILS</h4>
                <!-- {{clientData.banks}} -->
                <template v-if="checkProperty(clientData, 'banks') != '--'">
                  <template v-for="(iden, index) in clientData.banks">
                    <ul class="line" :key="index">
                      <li>
                        <label>Bank Name</label>
                        <p>
                          {{
                            getProperty(
                              iden.id,
                              checkProperty(clientData, 'bankDetails'),
                            )
                          }}
                        </p>
                      </li>

                      <li>
                        <label>Account Type</label>
                        <p>{{ checkProperty(iden, 'accType') }}</p>
                      </li>
                      <li>
                        <label>Bank Phone Number</label>
                        <p>{{ checkProperty(iden, 'phone') }}</p>
                      </li>
                      <li>
                        <label>Bank Account Number (used for state fees)</label>
                        <p>{{ checkProperty(iden, 'accNo') }}</p>
                      </li>

                      <li>
                        <label>Bank Account Name</label>
                        <p>{{ checkProperty(iden, 'accountName') }}</p>
                      </li>
                      <li>
                        <label>Bank Account Number</label>
                        <p>{{ checkProperty(iden, 'accNo') }}</p>
                      </li>
                      <li>
                        <label>Routing Number</label>
                        <p>{{ checkProperty(iden, 'routingNo') }}</p>
                      </li>
                      <li>
                        <label>Address</label>
                        <p>{{ checkProperty(iden, 'address', 'addr2') }}</p>
                      </li>
                      <li>
                        <label>Country </label>
                        <p>
                          {{
                            getProperty(
                              checkProperty(iden, 'address', 'cntryId'),
                              checkProperty(clientData, 'bankCountries'),
                            )
                          }}
                        </p>
                      </li>
                      <li>
                        <label>State</label>
                        <p>
                          {{
                            getProperty(
                              checkProperty(iden, 'address', 'stateId'),
                              checkProperty(clientData, 'bankStates'),
                            )
                          }}
                        </p>
                      </li>
                      <li>
                        <label>City</label>
                        <p>
                          {{
                            getProperty(
                              checkProperty(iden, 'address', 'cityId'),
                              checkProperty(clientData, 'bankCities'),
                            )
                          }}
                        </p>
                      </li>
                      <li>
                        <label>Zip</label>
                        <p>{{ checkProperty(iden, 'address', 'zip') }}</p>
                      </li>
                    </ul>
                  </template>
                </template>
              </div>

              <div class="questionnaire_details_cnt">
                <h4 class="title">SUPPLIER INFO</h4>
                <!-- {{clientData.supplier}} -->
                <template v-if="checkProperty(clientData, 'supplier') != '--'">
                  <template v-for="(iden, index) in clientData.supplier">
                    <ul :key="index" class="line">
                      <li :key="index">
                        <label>Supplier Type</label>
                        <template
                          v-for="(supplier, iindx) in checkProperty(
                            clientData,
                            'supplierTypes',
                          )"
                        >
                          <p :key="iindx">
                            {{ supplier.name }}
                          </p>
                        </template>
                      </li>
                      <li :key="index">
                        <label>Supplier Name</label>
                        <p>{{ checkProperty(iden, 'name') }}</p>
                      </li>
                      <li>
                        <label>Supplier Phone</label>
                        <p>
                          {{ checkProperty(iden, 'phone') }}
                        </p>
                      </li>
                      <li>
                        <label>Supplier Email</label>
                        <p>
                          {{ checkProperty(iden, 'email') }}
                        </p>
                      </li>

                      <li>
                        <label>Address</label>
                        <p>{{ checkProperty(iden, 'address', 'addr2') }}</p>
                      </li>
                      <li>
                        <label>Country</label>
                        <p>
                          {{
                            getProperty(
                              checkProperty(iden, 'address', 'cntryId'),
                              checkProperty(clientData, 'supplierCountries'),
                            )
                          }}
                        </p>
                      </li>
                      <li>
                        <label>State</label>
                        <p>
                          {{
                            getProperty(
                              checkProperty(iden, 'address', 'stateId'),
                              checkProperty(clientData, 'supplierStates'),
                            )
                          }}
                        </p>
                      </li>
                      <li>
                        <label>City</label>
                        <p>
                          {{
                            getProperty(
                              checkProperty(iden, 'address', 'cityId'),
                              checkProperty(clientData, 'supplierCities'),
                            )
                          }}
                        </p>
                      </li>
                      <li>
                        <label>Zip</label>
                        <p>{{ checkProperty(iden, 'address', 'zip') }}</p>
                      </li>
                    </ul>
                  </template>
                </template>
              </div>
              <div class="questionnaire_details_cnt">
                <h4 class="title title--normalcase">Inventory Locations</h4>
                <template
                  v-if="checkProperty(clientData, 'inventoryLocations') != '--'"
                >
                  <template
                    v-for="(iden, index) in clientData.inventoryLocations"
                  >
                    <ul class="line" :key="index">
                      <li>
                        <label>Inventory Type</label>

                        <template
                          v-for="(inventory, iindx) in checkProperty(
                            clientData,
                            'inventoryLocationTypes',
                          )"
                        >
                          <p :key="iindx">
                            {{ inventory.name }}
                          </p>
                        </template>
                      </li>
                      <li>
                        <label>Address</label>
                        <p>{{ checkProperty(iden, 'address', 'addr2') }}</p>
                      </li>
                      <li>
                        <label>Country </label>
                        <p>
                          {{
                            getProperty(
                              checkProperty(iden, 'address', 'cntryId'),
                              checkProperty(clientData, 'inventoryCountries'),
                            )
                          }}
                        </p>
                      </li>
                      <li>
                        <label>State</label>
                        <p>
                          {{
                            getProperty(
                              checkProperty(iden, 'address', 'stateId'),
                              checkProperty(clientData, 'inventoryStates'),
                            )
                          }}
                        </p>
                      </li>
                      <li>
                        <label>City</label>
                        <p>
                          {{
                            getProperty(
                              checkProperty(iden, 'address', 'cityId'),
                              checkProperty(clientData, 'inventoryCities'),
                            )
                          }}
                        </p>
                      </li>
                      <li>
                        <label>Zip</label>
                        <p>{{ checkProperty(iden, 'address', 'zip') }}</p>
                      </li>
                    </ul>
                  </template>
                </template>
              </div>
            </div>
          </div>
          <div
            class="questionnaire_details_list"
            id="ownerdetails"
            v-if="blocks.form3.active"
          >
            <div class="client_details_profile">
              <figure>
                <img
                  :src="checkProperty(clientData, (src = 'logo'))"
                  @error="setAltImg($event)"
                />
              </figure>
              <div v-if="checkProperty(clientData, 'owners') != '--'">
                <template v-for="(iden, index) in clientData.owners">
                  <div :key="index">
                    <figcaption>{{ checkProperty(iden, 'name') }}</figcaption>
                    <p>
                      <label>Phone</label>
                      <template
                        v-if="
                          checkProperty(iden, 'phoneCode') != '--' &&
                          checkProperty(iden, 'phoneCode') != ''
                        "
                      ></template>
                      {{ checkProperty(iden, 'phoneCode') }}
                      <template> {{ checkProperty(iden, 'phone') }}</template>
                    </p>

                    <p>
                      <label>Email</label>{{ checkProperty(iden, 'email') }}
                    </p>
                  </div>
                </template>
              </div>
            </div>
            <template v-if="checkProperty(clientData, 'owners') != '--'">
              <template v-for="(iden, index) in clientData.owners">
                <div :key="index">
                  <div class="questionnaire_details_cnt padt0">
                    <ul>
                      <!-- <li :key="index">
                                                <label>Full Name</label>
                                                <p>{{checkProperty( iden ,'name')}}</p>
                                            </li> -->
                      <li>
                        <label>Title</label>
                        <p>{{ checkProperty(iden, 'title') }}</p>
                      </li>
                      <li>
                        <label>Phone Number</label>
                        <p>
                          <template
                            v-if="
                              checkProperty(iden, 'phoneCode') != '--' &&
                              checkProperty(iden, 'phoneCode') != ''
                            "
                          ></template>
                          {{ checkProperty(iden, 'phone') }}
                        </p>
                      </li>
                      <li>
                        <label>Email</label>
                        <p>{{ checkProperty(iden, 'email') }}</p>
                      </li>
                      <li>
                        <label>Percentage Of Ownership</label>
                        <p>{{ checkProperty(iden, 'sharePercent') }}</p>
                      </li>
                      <li>
                        <label>SSN</label>
                        <p>{{ checkProperty(iden, 'SSN') }}</p>
                      </li>
                      <li>
                        <label>Date Of Birth</label>
                        <p
                          v-if="
                            checkProperty(iden, 'dateOfBirth') != '--' &&
                            checkProperty(iden, 'dateOfBirth') != ''
                          "
                        >
                          {{ checkProperty(iden, 'dateOfBirth') | formatDate }}
                        </p>
                        <p v-else>{{ checkProperty(iden, 'dateOfBirth') }}</p>
                      </li>
                      <li>
                        <label> Effective Date</label>
                        <p v-if="checkProperty(iden, 'titleEffDate') != '--'">
                          {{ checkProperty(iden, 'titleEffDate') | formatDate }}
                        </p>
                        <p v-else>--</p>
                      </li>
                      <li>
                        <label>Issued State</label>

                        <p>
                          {{
                            getProperty(
                              checkProperty(iden, 'licensedState'),
                              checkProperty(clientData, 'ownerLicensedStates'),
                            )
                          }}
                        </p>
                      </li>
                      <li>
                        <label>Expiration Date</label>
                        <p>
                          {{ checkProperty(iden, 'titleExpDate') | formatDate }}
                        </p>
                      </li>
                      <li>
                        <label>Driver’s License Number</label>
                        <p>{{ checkProperty(iden, 'driverLicenseNo') }}</p>
                      </li>
                      <li>
                        <label>Copy of Drivers License </label>

                        <p></p>

                        <ul class="documents_list">
                          <li
                            v-for="(other, indx) in iden.driverLicenseDocs"
                            :key="indx"
                          >
                            <p>{{ checkProperty(other, 'name') }}</p>
                            <a :href="other.url" target="_blank" download>
                              <b-icon
                                icon="download"
                                aria-hidden="true"
                              ></b-icon>
                            </a>
                            <!-- <a @click="download_file(other)"
                              ><b-icon
                                icon="download"
                                aria-hidden="true"
                              ></b-icon
                            ></a> -->
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div class="questionnaire_details_cnt">
                    <h4 class="title">ADDRESS</h4>
                    <ul>
                      <li>
                        <label>Address</label>
                        <p>{{ checkProperty(iden, 'address', 'addr2') }}</p>
                      </li>
                      <li>
                        <label>Country </label>
                        <p>
                          {{
                            getProperty(
                              checkProperty(iden, 'address', 'cntryId'),
                              checkProperty(clientData, 'ownerCountries'),
                            )
                          }}
                        </p>
                      </li>
                      <li>
                        <label>State</label>
                        <p>
                          {{
                            getProperty(
                              checkProperty(iden, 'address', 'stateId'),
                              checkProperty(clientData, 'ownerStates'),
                            )
                          }}
                        </p>
                      </li>
                      <li>
                        <label>City</label>
                        <p>
                          {{
                            getProperty(
                              checkProperty(iden, 'address', 'cityId'),
                              checkProperty(clientData, 'ownerCities'),
                            )
                          }}
                        </p>
                      </li>
                      <li>
                        <label>Zip</label>
                        <p>{{ checkProperty(iden, 'address', 'zip') }}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
            </template>
          </div>

          <div
            class="questionnaire_details_list"
            id="ownerdetails"
            v-if="blocks.form4.active"
          >
            <!-- <div class="questionnaire_details_head">
                                <h3>States already registered</h3>
                                <p>*Please ensure the information on this form is correct, including spelling, this is the exact information we give to the states</p>
                            </div> -->
            <div>
              <div class="questionnaire_details_cnt">
                <!-- getCredentials() -->
                <template v-if="checkProperty(clientData, 'stateReg') != '--'">
                  <template v-for="(iden, index) in clientData.stateReg">
                    <div class="register_states_list" :key="index">
                      <!-- {{clientData.stateReg}} -->
                      <h5
                        v-if="
                          checkProperty(iden, 'stateDetails', 'name') != '--'
                        "
                      >
                        {{ checkProperty(iden, 'stateDetails', 'name') }}
                      </h5>
                      <ul>
                        <li>
                          <label>Username</label>
                          <p v-if="checkProperty(iden, 'userName') != '--'">
                            {{ checkProperty(iden, 'userName') }}
                          </p>
                          <p v-else>*****</p>
                        </li>
                        <li>
                          <label>Password </label>
                          <div class="d-flex align-items-center">
                            <p
                              class="marr30"
                              v-if="checkProperty(iden, 'password') != '--'"
                            >
                              {{ checkProperty(iden, 'password') }}
                            </p>
                            <p class="marr30" v-else>*****</p>
                            <template v-if="iden.hasCredentials">
                              <div
                                class="eye_icon"
                                title="show"
                                @click="getCredentials(index, iden)"
                              >
                                <b-icon
                                  icon=" eye-fill"
                                  aria-hidden="true"
                                ></b-icon>
                              </div>
                            </template>
                            <template v-else>
                              <div
                                class="eye_icon"
                                title="hide"
                                @click="hideCredentials(index, iden)"
                              >
                                <b-icon
                                  icon="eye-slash-fill"
                                  aria-hidden="true"
                                ></b-icon>
                              </div>
                            </template>
                          </div>
                        </li>
                        <li>
                          <label>State</label>
                          <p>
                            {{ checkProperty(iden, 'stateDetails', 'name') }}
                          </p>
                        </li>
                        <li>
                          <label>Estimated Sales</label>
                          <p>{{ checkProperty(iden, 'estimatedSales') }}</p>
                        </li>
                        <li>
                          <label>Payment Frequency</label>
                          <p>
                            {{
                              checkProperty(iden, 'payFrequencyDetails', 'name')
                            }}
                          </p>
                        </li>
                        <li>
                          <label>Sales Tax Account Number</label>
                          <p>
                            {{ checkProperty(iden, 'salesTaxAccntNo') }}
                          </p>
                        </li>
                        <li>
                          <label>Security Question</label>
                          <p>
                            {{ checkProperty(iden, 'securityQuestion') }}
                          </p>
                        </li>
                        <li>
                          <label>Security Answer</label>
                          <p>
                            {{ checkProperty(iden, 'securityAnswer') }}
                          </p>
                        </li>
                        <!--<li>
                          <label>Payment On</label>
                          <p>{{ checkProperty(iden, "paymentOn") }}</p>
                        </li>
                        <li>
                          <label>Last Filed Date</label>
                          <p
                            v-if="checkProperty(iden, 'trxnStartDate') !== '--'"
                          >
                            {{
                              checkProperty(iden, "trxnStartDate") | formatDate
                            }}
                          </p>
                          <p v-else>--</p>
                        </li>-->
                      </ul>
                      <ul class="column-one">
                        <li>
                          <label>Sales Tax Permit Documents</label>
                          <ul class="documents_list">
                            <li
                              v-for="(doc, docIndex) in iden['documents']"
                              :key="docIndex"
                            >
                              <p>{{ checkProperty(doc, 'name') }}</p>
                              <a :href="doc.url" target="_blank" download>
                                <b-icon
                                  icon="download"
                                  aria-hidden="true"
                                ></b-icon>
                              </a>
                              <!-- <a @click="download_file(doc)"
                                ><b-icon
                                  icon="download"
                                  aria-hidden="true"
                                ></b-icon
                              ></a> -->
                              <!--  <a><b-icon icon="arrows-fullscreen" aria-hidden="true"></b-icon></a>-->
                            </li>
                            <!--
                                                            <li><p>North Carolina state pay</p><a><b-icon icon="download" aria-hidden="true"></b-icon></a><a><b-icon icon="arrows-fullscreen" aria-hidden="true"></b-icon></a></li>
                                                            <li><p>North Carolina state pay</p><a><b-icon icon="download" aria-hidden="true"></b-icon></a><a><b-icon icon="arrows-fullscreen" aria-hidden="true"></b-icon></a></li>
                                                             -->
                          </ul>
                          <ul>
                            <li>
                              <label
                                >Would you like TaxHero Inc. to be the mailing
                                address for all sales tax related letters,
                                notices and permits?</label
                              >
                              <p>
                                {{
                                  checkProperty(
                                    clientData,
                                    'wouldYouLikeTaxHero',
                                  )
                                }}
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </template>
                </template>
                <!-- <div class="register_states_list">
                                        <h5>Arizona</h5>
                                        <ul>

                                            <li>
                                                <label>Username</label>
                                                <p>Syam</p>
                                            </li>
                                            <li>
                                                <label>Password</label>
                                                <p>Password</p>
                                            </li>
                                            <li>
                                                <label>State</label>
                                                <p>california</p>
                                            </li>
                                            <li>
                                                <label>Estimated Sales</label>
                                                <p>10000000000</p>
                                            </li>
                                            <li>
                                                <label>Payment Frequency</label>
                                                <p>Yearly</p>
                                            </li>
                                            <li>
                                                <label>PaymentOn</label>
                                                <p>10</p>
                                            </li>
                                            <li>
                                                <label>Sales Tax Permit</label>
                                                <p>10</p>
                                            </li>
                                        </ul>
                                    </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showLoading" class="page_loader">
      <img src="@/assets/images/loader.gif" />
    </div>
  </div>
</template>

<script>
import profileHeader from '@/components/ProfileHeader.vue';
import _ from 'lodash';
import moment from 'moment';
//import uploadImage from "@/assets/images/upload_icon.svg"
import viewAddressComponent from '@/components/viewAddress.vue';

export default {
  name: 'questionnaire',
  watch: {},
  mounted() {
    this.getMaterData('market_place_type');
    this.togleBlock();
    if (_.has(this.userdata, 'clientDetails')) {
      this.Payload.clientId = this.userdata.clientDetails._id;
    } else if (_.has(this.$route.params, 'clientId')) {
      this.Payload.clientId = this.$route.params['clientId'];
    }
    this.getClientdetails();
  },

  components: {
    viewAddressComponent,
    profileHeader,
  },
  data() {
    return {
      isPasswordVisible: false,
      documents: [],
      showDiv: true,
      mailingAddressAsSame: false,
      market_place_type: [],

      show_block: 1,

      blocks: {
        form1: {
          active: true,
          validator: 'legal_informationForm',
          label: 'General Information',
          formIsValied: false,
        },
        form2: {
          active: false,
          validator: 'business_information',
          label: 'Business Information',
          formIsValied: false,
        },
        form3: {
          active: false,
          validator: 'owner_details',
          label: 'Owner Details',
          formIsValied: false,
        },
        form4: {
          active: false,
          validator: 'statesalreadyregistered',
          label: 'States Registered',
          formIsValied: false,
        },
      },

      clientData: null,
      Payload: {
        allMonths: [
          { name: 'January', id: 1 },
          { name: 'February', id: 2 },
          { name: 'March', id: 3 },
          { name: 'April', id: 4 },
          { name: 'May', id: 5 },
          { name: 'June', id: 6 },
          { name: 'July', id: 7 },
          { name: 'August', id: 8 },
          { name: 'September', id: 9 },
          { name: 'October', id: 10 },
          { name: 'November', id: 11 },
          { name: 'December', id: 12 },
        ],
        busEmail: '',
        EIN: '',
        clientId: '',
        busContact: {
          fName: 'Thamous',
          lName: 'N',
          phone: '',
          phoneCode: '',
          email: '',
          website: '',
        },
        busName: '',
        tradeName: '',
        busAddress: {
          addr1: '',
          addr2: '',
          stateId: null,
          cityId: null,
          zip: '',
          cntryId: 101,
        },
        mailingAddress: {
          addr1: '',
          addr2: '',
          stateId: null,
          cityId: null,
          zip: '',
          cntryId: 101,
        },
        taxRecordLocation: {
          addr1: '',
          addr2: '',
          stateId: null,
          cityId: null,
          zip: '',
          cntryId: 101,
        },
        fedaralEmpId: '',
        busFormatedOn: '2000-03-01',
        busStructureId: '', /// master
        busStructureDetails: null,
        incorporatedState: '', /// master
        incorporatedStateDetails: null,
        secretaryStateNumber: '',
        LLCID: '423423', //Doubt
        llcTypeIds: [],
        accMethodId: 2, /// master
        ownersMorethan20Percent: '',
        parentCompanyName: '',
        accMethodDetails: null,
        accClosedOn: '2020-10-10',
        taxBegginingPeriod: '',
        taxBegginingPeriodDetails: null,
        nexusTypeId: '', /// master
        nexusTypeDetails: null,
        itemDescription: '',
        NAICSCode: '',
        busTypeIds: [], /// master
        businessTypeDetails: [],
        doYouUserTaxzarApi: true,
        havePhysicalStore: false,
        sellingPlatformIds: [], /// master
        platformDetails: null,
        shopifyUrlOrAddress: '',
        marketPlaceFecilitator: true,
        sellOnMarketPlace: false,
        purchaseExistingBusiness: false,
        haveEmployeesOrAgents: true,
        otherBusStateIds: [], /// master
        otherBusStates: null,
        website: '',
        articlesOfOrgAndDriverLiecenses: [],
        banks: [],
        industryIds: [],
        bankDetails: [],
        supplier: [
          {
            typeId: '',
            typeDetails: '',
            name: '',
            addr1: '',
            addr2: '',
            stateId: '',
            cityId: '',
            zip: '',
            cntryId: 231,
            phone: '',
            phoneCode: '',
          },
        ],
        inventoryLocations: [
          {
            name: '',
            addr1: '',
            addr2: '',
            stateId: '',
            cityId: '',
            zip: '',
            cntryId: 231,
            phone: '',
            phoneCode: '',
          },
        ],
        stateRegs: null,

        owners: [
          {
            name: '',
            title: '',
            titleEffDate: '',
            addr1: '',
            addr2: '',
            stateId: '',
            cityId: '',
            zip: '',
            cntryId: '',
            phone: '',
            phoneCode: '',
            email: '',
            dateOfBirth: '',
            SSN: '',
            sharePercent: '',
            driverLicenseNo: '',
            driverLicenseDocs: [
              {
                name: '',
                url: '',
                mimeType: '',
                extn: '',
              },
            ],
            licensedState: '', // satateId of us Country
          },
        ],
        stateReg: [
          /*
            {
			"_id":"", // Required in update state registration
			"stateId": '', //dropdown
			"estimatedSales": '', //number input
			"payFrequencyId": '', /// master Drop down
			"paymentOn": '',  number input // Day Number
			"documents": [],
			"userName": "",
			"password": "",
			"operating": true, // check box
			"status": true //pass false while delete stateREG
        }*/
        ],
        identifiers: [
          {
            _id: '', // Required in update state identifier
            marketPlace: 1, //master Data
            identifier: '',
          },
        ],
        logo: '',
        //"profCompleted": "Yes" // Send 'Yes' on final submission
      },
      llc_type: [],
      allMonths: [
        { name: 'January', id: 1 },
        { name: 'February', id: 2 },
        { name: 'March', id: 3 },
        { name: 'April', id: 4 },
        { name: 'May', id: 5 },
        { name: 'June', id: 6 },
        { name: 'July', id: 7 },
        { name: 'August', id: 8 },
        { name: 'September', id: 9 },
        { name: 'October', id: 10 },
        { name: 'November', id: 11 },
        { name: 'December', id: 12 },
      ],
      clientTypeId: 'password',
      clientTypeSecret: 'password',
      bus_structure: [],
      bus_type: [],
      nexus: [],
      platform: [],
      pay_frequency: [],
      acc_method: [],
      usstateList: [],
      bank: [],
      usCountry: {
        _id: 231,
        sName: 'US',
        name: 'United States',
        phoneCode: 1,
        curSymbol: '$',
        curCode: 'USD',
        zipLen: 5,
      },
      phoneCode: null,
      phone: null,
      ClientDataloaded: true,
    };
  },
  methods: {
    getMarketplaceById(id) {
      const market = this.market_place_type.find((item) => item._id === id);
      return market && market.name !== 'Other' ? market.name : '';
    },
    getMaterData(category) {
      let payLoad = {
        matcher: {
          searchString: '',
          //"cntryId": 101,
          //"stateId": 665,
          //"cntryIds": [38], // Requied for "state"
          //"stateIds": [665], // Requied for "city"
        },
        page: 1,
        perpage: 10000000000,
        category: category, // acc_method, bank, user_status, user_role, user_list, country, state, platform, business, bus_type, notes_category, notes_status, client_status, nexus, pay_frequency
      };
      this.$store
        .dispatch('getMaterData', payLoad)
        .then((res) => {
          let dt = res.list;
          //if(category =="llc_type"){
          _.forEach(dt, (obj, index) => {
            dt[index]['selected'] = false;
            this[category].push(dt[index]);
          });

          //  }else{
          //     this[category] = dt;
          //  }
        })
        .catch((err) => {
          this.log(err);
        });
    },
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    getMonthName() {
      if (_.has(this.clientData, 'taxBegginingPeriod')) {
        let month = _.find(this.allMonths, {
          id: this.clientData['taxBegginingPeriod'],
        });
        if (month) {
          return month.name;
        } else {
          return '';
        }
      } else {
        return '';
      }
    },
    changedGlobalclient(cl) {
      if (
        cl != null &&
        _.has(cl, '_id') &&
        cl['_id'] != null &&
        cl['_id'] != 'null' &&
        this.Payload.clientId != cl['_id']
      ) {
        this.Payload.clientId = cl['_id'];

        // alert(this.Payload.clientId +"===="+ cl["_id"])
        //this.$router.go('/client-details/'+cl["_id"]);
      } else if (_.has(this.userdata, 'clientDetails')) {
        this.Payload.clientId = this.userdata.clientDetails._id;
      } else if (_.has(this.$route.params, 'clientId')) {
        this.Payload.clientId = this.$route.params['clientId'];
      }
      this.getClientdetails();
    },
    generateData() {
      this.$store
        .dispatch('getList', {
          data: { clients: [this.Payload.clientId] },
          path: '/transactions/create',
        })
        .then((response) => {
          console.log(response);
          this.$store.dispatch('getList', {
            data: { clientId: this.Payload.clientId },
            path: '/filings/migrate',
          });
          ///filings/migrate
        })
        .catch((err) => {
          this.log(err);
        });
    },
    getProperty(value = '', array_obj) {
      if (value != '' && array_obj) {
        let obj = _.find(array_obj, { _id: value });
        if (obj && _.has(obj, 'name')) {
          return obj['name'];
        } else {
          return '';
        }
      } else {
        return '';
      }
    },
    getNameFromObject({
      object = null,
      namekey = 'name',
      idkey = '_id',
      idValue = -1,
    }) {
      if (
        (object && _.has(object, namekey) && _.has(object, idkey),
        object[idkey] == idValue)
      ) {
        return object[namekey];
      } else {
        return '';
      }
    },
    hideCredentials(index, itemModel) {
      this.log(itemModel);
      itemModel['hasCredentials'] = true;
      itemModel.userName = '*****';
      itemModel.password = '*****';
    },
    getCredentials(index, itemModel) {
      if (
        _.has(itemModel, '_id') &&
        itemModel._id != '' &&
        itemModel._id != undefined &&
        itemModel._id != null
      ) {
        this.$store
          .dispatch('getCredentials', {
            clientId: this.Payload.clientId,
            matrixId: itemModel._id,
          })
          .then((res) => {
            // alert(JSON.stringify(res));
            itemModel.userName = _.cloneDeep(res.userName);
            itemModel.password = _.cloneDeep(res.password);
            itemModel.hasCredentials = false;
          })
          .catch((err) => {
            console.log(err);
            itemModel.userName = '*****';
            itemModel.password = '*****';
          });
      } else {
        itemModel.userName = '*****';
        itemModel.password = '*****';
      }
    },

    updateNexusTypeDetails(item) {
      if (_.has(item, '_id')) {
        this.Payload.nexusTypeId = item._id;
      } else {
        this.Payload.nexusTypeId = '';
      }
    },

    updateIncorporatedState(item) {
      this.Payload.articlesOfOrgAndDriverLiecenses = [];
      if (item && _.has(item, '_id')) {
        this.Payload.incorporatedState = item._id;
      } else {
        this.Payload.incorporatedStateDetails = null;
        this.Payload.incorporatedState = '';
      }
    },

    selectedAccount(method) {
      _.forEach(this.acc_method, (obj, index) => {
        this.acc_method[index]['selected'] = false;
        if (obj['_id'] == method['_id']) {
          this.acc_method[index]['selected'] = true;
        }
      });

      this.Payload.accMethodDetails = method;
      this.Payload.accMethodId = method._id;
    },

    togleBlock() {
      let index = 1;
      _.forEach(this.blocks, (item, key) => {
        //this.log(key ,item);

        if (this.show_block == index) {
          this.blocks[key].active = false;
          this.blocks[key].active = true;
          item.active = false;
          item.active = true;
        } else {
          this.blocks[key].active = false;
          item.active = false;
        }
        index = index + 1;
      });
    },

    getClientdetails() {
      this.ClientDataloaded = false;
      this.$store
        .dispatch('getClientdetails', { clientId: this.Payload.clientId })
        .then((res) => {
          this.clientData = res;
          //this.Payload =res;
          this.Payload['busContact']['fName'] = this.userdata['fName'];
          this.Payload['busContact']['fName'] = this.userdata['fName'];
          this.Payload['busContact']['phone'] = this.userdata['phone'];
          this.Payload['busContact']['phoneCode'] = this.userdata['phoneCode'];
          this.Payload['busContact']['website'] = this.userdata['website'];
          this.processData();

          this.togleBlock();
          this.ClientDataloaded = true;
        })
        .catch((err) => {
          this.log(err);
          this.ClientDataloaded = true;
        });
    },
    processData() {
      if (_.has(this.clientData, 'ownersMorethan20Percent')) {
        this.Payload.ownersMorethan20Percent =
          this.clientData['ownersMorethan20Percent'];
      }
      if (_.has(this.clientData, 'parentCompanyName')) {
        this.Payload.parentCompanyName = this.clientData['parentCompanyName'];
      }
      //General Information
      this.ClientDataloaded = false;

      this.Payload.taxRecordLocation = this.clientData.taxRecordLocation;
      this.Payload.busName = this.clientData.busName;
      this.Payload.tradeName = this.clientData.tradeName;
      this.Payload.logo = this.clientData.logo;

      //Business Information
      this.Payload.busContact = this.clientData.busContact;
      this.Payload.fedaralEmpId = this.clientData.fedaralEmpId;
      this.Payload.busFormatedOn = moment(this.clientData.busFormatedOn).format(
        'YYYY-MM-DD',
      );
      this.Payload.busStructureId = this.clientData.busStructureId;
      this.Payload.busStructureDetails = this.clientData.busStructureDetails;
      this.Payload.secretaryStateNumber = this.clientData.secretaryStateNumber;
      if (_.has(this.clientData, 'incorporatedState')) {
        this.Payload.incorporatedState = this.clientData.incorporatedState;
        this.Payload.incorporatedStateDetails = _.find(this.usstateList, {
          _id: this.Payload.incorporatedState,
        });

        //alert(this.Payload.incorporatedStateDetails);
        if (this.Payload.incorporatedStateDetails)
          this.updateIncorporatedState(this.Payload.incorporatedStateDetails);
      }

      if (_.has(this.clientData, 'articlesOfOrgAndDriverLiecenses')) {
        this.Payload.articlesOfOrgAndDriverLiecenses =
          this.clientData.articlesOfOrgAndDriverLiecenses;
      }
      //Payload.llcTypeIds
      if (_.has(this.clientData, 'llcTypeIds')) {
        this.Payload.llcTypeIds = this.clientData.llcTypeIds;
        _.forEach(this.llc_type, (obj) => {
          if (this.Payload.llcTypeIds.indexOf(obj._id) > -1) {
            obj.selected = true;
          }
        });
      }
      //accMethodDetails // accMethodId
      if (_.has(this.clientData, 'accMethodDetails')) {
        this.Payload.accMethodDetails = this.clientData['accMethodDetails'];
        this.selectedAccount(this.clientData['accMethodDetails']);
      }

      if (_.has(this.clientData, 'accClosedOn')) {
        this.Payload.accClosedOn = moment(
          this.clientData['accClosedOn'],
        ).format('YYYY-MM-DD');
      }
      if (_.has(this.clientData, 'industryIds')) {
        this.Payload.industryIds = this.clientData.industryIds;
      }
      if (_.has(this.clientData, 'nexusTypeDetails')) {
        this.Payload.nexusTypeDetails = this.clientData['nexusTypeDetails'];
        this.updateNexusTypeDetails(this.Payload.nexusTypeDetails);
      }

      //itemDescription
      if (_.has(this.clientData, 'itemDescription')) {
        this.Payload.itemDescription = this.clientData['itemDescription'];
      }
      //NAICSCode
      if (_.has(this.clientData, 'NAICSCode')) {
        this.Payload.NAICSCode = this.clientData['NAICSCode'];
      }
      if (_.has(this.clientData, 'busTypeId')) {
        this.Payload.busTypeId = this.clientData['busTypeId'];
      }

      if (_.has(this.clientData, 'businessTypeDetails')) {
        this.Payload.businessTypeDetails =
          this.clientData['businessTypeDetails'];
      }
      if (_.has(this.clientData, 'busEmail')) {
        this.Payload.busEmail = this.clientData['busEmail'];
      }
      if (_.has(this.clientData, 'EIN')) {
        this.Payload.EIN = this.clientData['EIN'];
      }
      if (_.has(this.clientData, 'taxBegginingPeriod')) {
        this.Payload.taxBegginingPeriod = this.clientData['taxBegginingPeriod'];
        this.Payload['taxBegginingPeriodDetails'] = _.find(this.allMonths, {
          id: this.Payload.taxBegginingPeriod,
        });
      }

      // busTypeId

      if (_.has(this.clientData, 'sellingPlatformIds')) {
        this.Payload.sellingPlatformIds = this.clientData['sellingPlatformIds'];
        if (this.Payload.sellingPlatformIds.length > 0) {
          this.Payload.platformDetails = [];
          _.forEach(this.platform, (item) => {
            if (this.Payload.sellingPlatformIds.indexOf(item._id) > -1) {
              this.Payload.platformDetails.push(item);
            }
          });
        }
      }
      //identifiers
      if (_.has(this.clientData, 'identifiers')) {
        this.Payload.identifiers = this.clientData.identifiers;
      }

      //"marketPlaceFecilitator": true, "sellOnMarketPlace": false, "purchaseExistingBusiness": false,
      if (_.has(this.clientData, 'marketPlaceFecilitator')) {
        this.Payload.marketPlaceFecilitator =
          this.clientData.marketPlaceFecilitator;
      }
      if (_.has(this.clientData, 'sellOnMarketPlace')) {
        this.Payload.sellOnMarketPlace = this.clientData.sellOnMarketPlace;
      }

      /* need to discuss with satish
     ///If So, Please List
    if(_.has(this.clientData ,"sellOnMarketPlace")){
            this.Payload.sellOnMarketPlace = this.clientData.sellOnMarketPlace;
        }
    /*/

      if (_.has(this.clientData, 'purchaseExistingBusiness')) {
        this.Payload.purchaseExistingBusiness =
          this.clientData.purchaseExistingBusiness;
      }
      if (_.has(this.clientData, 'haveEmployeesOrAgents')) {
        this.Payload.haveEmployeesOrAgents =
          this.clientData.haveEmployeesOrAgents;
      }

      //otherBusStates
      if (_.has(this.clientData, 'otherBusStates')) {
        this.Payload.otherBusStates = this.clientData['otherBusStates'];
      }

      if (_.has(this.clientData, 'website')) {
        this.Payload.website = this.clientData.website;
      }

      if (_.has(this.clientData, 'banks')) {
        this.Payload.banks = this.clientData['banks'];
        this.Payload.bankDetails = [];

        if (this.Payload.banks.length > 0) {
          _.forEach(this.Payload.banks, (b, index) => {
            let bank = _.find(this.bank, { _id: b.id });

            if (bank) {
              bank['selected'] = true;
              this.Payload.bankDetails.push(bank);
              this.Payload.banks[index]['name'] = bank['name'];
            }
          });
        }
      }

      if (_.has(this.clientData, 'supplier')) {
        this.ClientDataloaded = false;
        let sup = this.clientData['supplier'];
        this.Payload.supplier = [];
        _.forEach(sup, (item) => {
          this.Payload.supplier.push(item);
        });
      }
      if (_.has(this.clientData, 'inventoryLocations')) {
        this.ClientDataloaded = false;
        let sup = this.clientData['inventoryLocations'];
        this.Payload.inventoryLocations = [];
        _.forEach(sup, (item) => {
          this.Payload.inventoryLocations.push(item);
        });
      }
      ///owners

      if (_.has(this.clientData, 'owners')) {
        this.ClientDataloaded = false;
        let sup = this.clientData['owners'];
        this.Payload.owners = [];
        _.forEach(sup, (item) => {
          this.Payload.owners.push(item);
        });

        this.ClientDataloaded = true;
      }

      //stateRegistrations
      ///owners

      if (_.has(this.clientData, 'stateReg')) {
        this.ClientDataloaded = false;
        let sup = _.cloneDeep(this.clientData['stateReg']);
        this.Payload.stateReg = [];
        this.Payload.stateRegs = [];
        _.forEach(sup, (item) => {
          let state = _.find(this.usstateList, { _id: item.stateId });

          if (state) {
            this.Payload.stateRegs.push(state);
          }
          item['show'] = true;
          item['status'] = true;
          this.Payload.stateReg.push(item);
        });
        this.log(JSON.stringify(this.Payload.stateRegs));

        this.ClientDataloaded = true;
      }

      if (_.has(this.clientData, 'logo')) {
        this.Payload.logo = this.clientData['logo'];
      }
      if (_.has(this.clientData, 'doYouUserTaxzarApi')) {
        this.Payload.doYouUserTaxzarApi = this.clientData['doYouUserTaxzarApi'];
      }
      if (_.has(this.clientData, 'shopifyUrlOrAddress')) {
        this.Payload.shopifyUrlOrAddress = this.clientData.shopifyUrlOrAddress;
      }
      if (_.has(this.clientData, 'havePhysicalStore')) {
        this.Payload.havePhysicalStore = this.clientData['havePhysicalStore'];
      }

      this.ClientDataloaded = true;
    },
  },
  computed: {
    showLoading: {
      // return this.$store.getters["isloading"];
      //return false;
      get() {
        return this.$store.getters['isloading'];
      },
      set(value) {
        this.value = value;
      },
    },
  },
  beforeCreate() {
    //console.log(this.showLoading);
  },
};
</script>
